@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --color-light-tan: #fcfbfa;
  --color-dark-tan: #d9d5d1;
  --soil: #503a3a;
  --sage: #91b89e;
  --cantelope: #ffb885;
  --oat: #ebe4de;
  --evergreen: #45594e;
  --ivory: #fbf6d9;
  --daisy: #ecd768;
  --white: white;
  --olive: #b8b591;
  --plum: #b89191;
  --thistle: #91a0b8;
  --ivory-2: #fbf6d9;
}

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  table {
    @apply text-left;
  }

  td,
  th {
    @apply p-2;
  }

  button {
    @apply rounded-sm px-3 py-2 text-center;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: "Matter", sans-serif;
  scroll-behavior: smooth;
}

::selection {
  background: var(--cantelope);
  color: var(--soil);
}

.a-footer hr {
  border-color: #d6d1d0;
}

.circle {
  background-color: #88735d;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  left: calc(50% - 1.5em);
  align-items: center;
  position: absolute;
  top: -1.5em;
  z-index: 100;
}

.circle svg {
  height: 1.65em;
}

/** Loading animation */

.after-loader-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.after-loader-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #73635b;
  border-radius: 50%;
  animation: after-loader-default 1.2s linear infinite;
}
.after-loader-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.after-loader-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.after-loader-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.after-loader-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.after-loader-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.after-loader-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.after-loader-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.after-loader-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.after-loader-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.after-loader-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.after-loader-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.after-loader-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes after-loader-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: transparent;
  background: linear-gradient(
    to right,
    var(--oat) 8%,
    #b6a597 18%,
    var(--oat) 33%
  );
  background-size: 800px 104px;
  position: relative;
}
